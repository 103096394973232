import React, {useEffect, useRef, useState} from 'react';
import logoPR from "../assets/GaleriesLafayettes/logoPR.jpeg";
import galeriesLafayettes from '../../src/assets/Home pictures/galeriesLafayettes.jpg'
import Molitor from '../../src/assets/Home pictures/Molitor.jpeg'
import grandSalon from '../../src/assets/Home pictures/grandSalon.jpg'
import grandPalais from '../../src/assets/Home pictures/grandPalais.jpeg'
import {useHistory} from "react-router-dom";
import Footer from "../components/Footer";
import {useTranslation} from "react-i18next";

export default function HomePage() {

    const {t} = useTranslation();

    const [widthRightPart, setWidthRightPart] = useState(0)
    const [widthLeftPart, setWidthLeftPart] = useState(300)
    const [marginBottom, setMarginBottom] = useState(-140)

    const [opacity1, setOpacity1] = useState(0)
    const [opacity2, setOpacity2] = useState(0)
    const [opacity3, setOpacity3] = useState(0)
    const [opacity4, setOpacity4] = useState(0)

    const history = useHistory();

    const [widthFooter, setWidthFooter] = useState('0')

    function GoProjects() {

        setOpacity1(0)
        setOpacity2(0)
        setOpacity3(0)
        setOpacity4(0)
        setMarginBottom(-140)
        setTimeout(() => {
            history.push('/projects')
        }, 1000);
    }

    useEffect(() => {

        setTimeout(() => {
            if (width < 1000) {
                setWidthRightPart(1170)
                setWidthLeftPart(300)
            } else {
                setWidthRightPart(1070)
                setWidthLeftPart(400)
            }

            setTimeout(() => {
                setMarginBottom(0)
                setOpacity1(1)
                setTimeout(() => {
                    setOpacity2(1)
                    setTimeout(() => {
                        setOpacity3(1)
                        setTimeout(() => {
                            setOpacity4(1)
                        }, 800);
                    }, 800);
                }, 800);
            }, 800);
        }, 800);


    }, [setOpacity1, setOpacity2, setOpacity3, setOpacity4]);

    const refFooter = useRef();

    function handleClickOutside(event) {
        if (refFooter.current && !refFooter.current.contains(event.target)) {
            setWidthFooter('0');
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refFooter]);

    const ref = useRef(null);


    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
    })

    function Update() {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    return (
        <>
            {width < 600 && width < height ?
                <div className={'page'} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <div className={width < 600 ? 'littleHomePage' : 'homePage'}
                         style={{opacity: widthFooter === '0' ? 1 : 0.4}}>

                        <div className={'leftPart'}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                width: width < 1000 ? 200 : 300
                            }}>
                                <img src={logoPR} alt="" className={'agencyLogo'}/>

                                <div style={{display: 'flex'}}>

                                    <div className={'button'} onClick={() => GoProjects()}>
                                        <p>{t('home_page.realisations')}
                                        </p>
                                        <div className={'bar'} style={{marginBottom: 50,}}/>
                                    </div>

                                    <div className={'button'} style={{marginBottom: marginBottom, marginLeft: 20}}
                                         onClick={() => setWidthFooter('calc(100vw - 485px)')}>
                                        <p>{t('home_page.contact')}</p>
                                        <div className={'bar'}/>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className={'picturesPart'} style={{overflow: 'hidden', minWidth: 0}}>

                            <div style={{display: 'flex'}}>
                                <img src={grandSalon} alt="" className={'image1'} style={{opacity: opacity1}}/>
                                <img src={galeriesLafayettes} alt="" className={'image2'} style={{opacity: opacity2}}
                                     ref={ref}/>
                            </div>

                            <div style={{display: 'flex', marginTop: 2}}>
                                <img src={grandPalais} alt="" className={'image3'} style={{opacity: opacity3}}/>
                                <img src={Molitor} alt="" className={'image4'} style={{opacity: opacity4}}/>
                            </div>

                        </div>


                    </div>

                    <div ref={refFooter}>
                        <Footer setWidthFooter={setWidthFooter} widthFooter={widthFooter} isPhone={true}/>
                    </div>
                </div>
                :
                <div className={'page'} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <div className={width < 1000 ? 'littleHomePage' : 'homePage'}
                         style={{opacity: widthFooter === '0' ? 1 : 0.4}}>


                        <div className={'leftPart'} style={{width: widthLeftPart}}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                width: width < 1000 ? 200 : 300
                            }}>
                                <img src={logoPR} alt="" className={'agencyLogo'}/>


                                <div className={'button'} style={{opacity: opacity1}} onClick={() => GoProjects()}>
                                    <p>{t('home_page.realisations')}
                                    </p>
                                    <div className={'bar'} style={{marginBottom: 20,}}/>
                                </div>

                                <div className={'button'} style={{opacity: opacity1, marginBottom: marginBottom}}
                                     onClick={() => setWidthFooter('calc(100vw - 485px)')}>
                                    <p>{t('home_page.contact')}</p>
                                    <div className={'bar'}/>
                                </div>
                            </div>

                        </div>

                        <div className={'picturesPart'}
                             style={{width: widthRightPart, overflow: 'hidden', minWidth: 0}}>

                            <div style={{display: 'flex'}}>
                                <img src={grandSalon} alt="" className={'image1'} style={{opacity: opacity1}}/>
                                <img src={galeriesLafayettes} alt="" className={'image2'} style={{opacity: opacity2}}
                                     ref={ref}/>
                            </div>

                            <div style={{display: 'flex', marginTop: 2}}>
                                <img src={grandPalais} alt="" className={'image3'} style={{opacity: opacity3}}/>
                                <img src={Molitor} alt="" className={'image4'} style={{opacity: opacity4}}/>
                            </div>

                        </div>


                    </div>

                    <div ref={refFooter}>
                        <Footer setWidthFooter={setWidthFooter} widthFooter={widthFooter}/>
                    </div>
                </div>
            }
        </>
    )
}

