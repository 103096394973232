import React, {useEffect, useState} from 'react';
import FooterPart from "./FooterPart";
import FooterPartDev from "../components dev/FooterPartDev";
import logoPrNoir from '../../src/assets/Icons/logoPrNoir.jpeg'
import crossWhite from '../../src/assets/Icons/crossWhite.svg'
import {useTranslation} from "react-i18next";

const logoStyle = {
    width: 400,
    marginTop: 100
};

const crossStyle = {
    position: 'absolute',
    width: 30,
    height: 30,
    left: 0,
    marginLeft: 20,
    marginTop: 20
};

const middleSectionStyle = {
    marginBottom: 70,
    marginTop: 90,
    height: 'calc(100vh - 450px)'
};

const legalMentionsTitle = 'MENTIONS LÉGALES';
const privacyTitle = 'CONFIDENTIALITÉ';
const cookieManagementTitle = 'GESTION DES COOKIES';

export default function Footer({widthFooter, setWidthFooter, isPhone, projectPage}) {

    const isDev = process.env.NODE_ENV === "development";

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
    })

    function Update() {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    const { t } = useTranslation();

    return(
        <div className={isPhone ? 'phoneFooter' : 'footer'} style={{width: isPhone ? projectPage ? '100vw' : widthFooter === '0' ? 0 : '100vw' : widthFooter, height: isPhone ? projectPage ? null : '100vh' : '100vh', position: isPhone ? projectPage ? null : 'absolute' : "absolute"}}>
            <img src={logoPrNoir} alt="" style={logoStyle} />
            {projectPage ? null : <img onClick={() => setWidthFooter('0')} className={'hover'} src={crossWhite} alt="" style={crossStyle}/>}
            <div style={middleSectionStyle}>
                <div style={{display: 'flex', flexDirection: isPhone ? 'column' : 'row', marginTop: isPhone ? -30 : null}}>
                    <FooterPart
                        title={'PERROT & RICHARD Architectes'}
                        subtitle1={'60 rue Saint-André-des-Arts, 75006 Paris'}
                        subtitle2={'Tel: +33 (0)1 53 30 00 80'}
                        subtitle3={'contact@perrot-richard.com'}
                        isPhone={isPhone}
                    />
                    <div style={{width: isPhone ? 30 : 70}}/>
                    <FooterPart
                        title={'PERROT & RICHARD International'}
                        subtitle1={'Rue de la democratie Ebene junction, +230 Rose-Hill, MU'}
                        subtitle2={'Tel: (+230) 463 0092'}
                        subtitle3={'contact@perrot-richard-international.com'}
                        isPhone={isPhone}
                    />

                </div>
                <div style={{display: 'flex', marginTop: isPhone ? 0 : 60}}>
                    <FooterPart
                        title={t('agency_informations.press')}
                        subtitle1={'presse@perrot-richard.com'}
                        isPhone={isPhone}
                    />
                    <div style={{width: isPhone ? 30 : 70}}/>
                    {isDev ? <FooterPartDev
                        isPhone={isPhone}
                        title={'Développement'}
                        subtitle1={'Nouveau project'}
                        subtitle2={'Gérer les projets'}
                    /> : null}
                </div>
            </div>

            {height > 600 &&
                <div style={{display: 'flex', marginTop: isPhone ? 30 : null}}>
                    <p className={'linkLegalsTitle'} style={{marginLeft: 0}}>{t('legal.legal_notices')}</p>
                    <p className={'linkLegals'}>-</p>
                    <p className={'linkLegalsTitle'}>{t('legal.confidentiality')}</p>
                    <p className={'linkLegals'}>-</p>
                    <p className={'linkLegalsTitle'}>{t('legal.cookies')}</p>
                </div>
            }


        </div>
    )
}


