import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "./style/index.scss";
import {initReactI18next, useTranslation} from "react-i18next";
import i18next from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        supportedLngs: ['en', 'fr', 'sp'],
        detection: {
            order: ['htmlTag', 'cookie', 'localStorage', 'path', 'subdomain'],
            caches: ['cookie'] ,
        },
        backend: {
            loadPath: '/assets/locales/{{lng}}/translation.json'
        },
        react: { useSuspense: false},
    });

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
