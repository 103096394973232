import React from "react";

export default function FooterPart({title, subtitle1, subtitle2, subtitle3, isPhone}) {

    const isDev = process.env.NODE_ENV === "development";

    return (
        <div className={'footerPart'} style={{width: isPhone ? null : 'calc(100%/2.4)', marginBottom: isPhone ? 40 : null}}>
            <p className={'title'}>{title}</p>
            <p className={'subtitle'}>{subtitle1}</p>

            {subtitle2 &&
                <p className={'subtitle'}>{subtitle2}</p>
            }

            {subtitle3 &&
                <p className={'subtitle'}>{subtitle3}</p>
            }
        </div>
    )
}
