import React, {useEffect, useRef, useState} from 'react';
import Footer from "../components/Footer";
import logoPR from '../../src/assets/GaleriesLafayettes/logoPR.jpeg'
import leftArrow from '../../src/assets/Icons/leftArrow.svg'
import ProjectPictures from "../components/ProjectPictures";
import {useHistory} from "react-router-dom";
import {firestore} from "../firebase/config";

export default function ProjectsPage() {

    const history = useHistory();

    const maDiv = useRef(null);

    function remonterEnHaut() {
        maDiv.current.scrollTop = 0;
    }

    const [widthFooter, setWidthFooter] = useState('0')
    const [opacityContact, setOpacityContact] = useState(0)

    // Array of projects
    const [projects, setProjects] = useState([]);
    const [indexProject, setIndexProject] = useState(0);

    useEffect(() => {
        firestore.collection('projects').orderBy('index').get().then((snapshot) => {
            if (snapshot) {
                const projects = [];
                snapshot.forEach((doc) => {
                    if (doc && doc.exists) {
                        projects.push(doc.data());
                    }
                });
                for (const pic of projects[0].pictures) {
                    new Image().src = pic;
                }
                projects[0].isLoad = true;
                for (const pic of projects[1].pictures) {
                    new Image().src = pic;
                }
                projects[1].isLoad = true;
                for (const pic of projects[projects.length - 1].pictures) {
                    new Image().src = pic;
                }
                projects[projects.length - 1].isLoad = true;
                setProjects(projects);
            }
        });
    }, []);

    // Animation

    const [opacityProjectName, setOpacityProjectName] = useState(0)
    const [marginBottomPojectName, setMarginBottomPojectName] = useState(-280)

    const [opacityProjects, setOpacityProjects] = useState(0)
    const [marginTopProject, setMarginTopProjects] = useState(200)

    useEffect(() => {
        setMarginBottomPojectName(200)
        setOpacityProjectName(1)
        setOpacityProjects(1)
        setMarginTopProjects(0)
        setTimeout(() => {
            setOpacityContact(1)
        }, 1000);
    }, []);

    function nextProject() {
        setMarginTopProjects(200)
        setOpacityProjects(0)
        const newIndex = indexProject === projects.length - 2 ? 0 : indexProject === projects.length - 1 ? 1 : indexProject + 2;
        if (!projects[newIndex].isLoad) {
            for (const pic of projects[newIndex].pictures) {
                new Image().src = pic;
            }
        }
        setTimeout(() => {
            if (indexProject === projects.length - 1) {
                setIndexProject(0)
            } else {
                setIndexProject(prevState => prevState + 1)
            }
            setMarginTopProjects(0)
            setOpacityProjects(1)
        }, 1000);
        setTimeout(() => {
            maDiv.current.scrollTop = 0;
        }, 1000);
    }

    function previousProject() {
        setMarginTopProjects(200)
        setOpacityProjects(0)
        const newIndex = indexProject === 0 ? projects.length - 2 : indexProject === 1 ? projects.length - 1 : indexProject - 2;
        if (!projects[newIndex].isLoad) {
            for (const pic of projects[newIndex].pictures) {
                new Image().src = pic;
            }
        }
        setTimeout(() => {
            if (indexProject === 0) {
                setIndexProject(projects.length - 1);
            } else {
                setIndexProject(prevState => prevState - 1)
            }
            setMarginTopProjects(0)
            setOpacityProjects(1)
        }, 1000);

        setTimeout(() => {
            maDiv.current.scrollTop = 0;
        }, 1000);
    }

    const refFooter = useRef();

    function handleClickOutside(event) {
        if (refFooter.current && !refFooter.current.contains(event.target)) {
            setWidthFooter('0');
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refFooter]);


    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
    })

    function Update() {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    const [touchStartX, setTouchStartX] = useState(0);
    const [touchCurrentX, setTouchCurrentX] = useState(0);
    const [touchStartY, setTouchStartY] = useState(0);
    const [touchCurrentY, setTouchCurrentY] = useState(0);
    const [swipeHandled, setSwipeHandled] = useState(false);

    const handleTouchStart = (e) => {
        setTouchStartX(e.touches[0].clientX);
        setTouchStartY(e.touches[0].clientY);
    }

    const handleTouchMove = (e) => {
        if (swipeHandled) return;  // Si le swipe a déjà été traité, nous ne faisons rien

        setTouchCurrentX(e.touches[0].clientX);
        setTouchCurrentY(e.touches[0].clientY);

        const diffX = touchCurrentX - touchStartX;
        const diffY = touchCurrentY - touchStartY;

        if (Math.abs(diffX) > Math.abs(diffY)) {
            if (diffX > 70) {
                previousProject();
                setSwipeHandled(true); // Marque le swipe comme traité
            } else if (diffX < -70) {
                nextProject();
                setSwipeHandled(true); // Marque le swipe comme traité
            }
        }
    }

    const handleTouchEnd = () => {
        setSwipeHandled(false); // Réinitialise l'état du swipe
    }

    return (
        <>
            {width < 600 && width < height ?
                <div
                    className={'phonePage'}
                    style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
                    <div className={'phoneProjectPage'} style={{opacity: widthFooter === '0' ? 1 : 0.4}}
                         onClick={() => {
                             if (widthFooter !== '0') {
                                 setWidthFooter('0')
                             }
                         }}>
                        <div className={'leftPart'}>
                            <div className={'leftPartContent'}>

                                <img src={logoPR} alt="" className={'agencyLogo'}
                                     onClick={() => history.push('/')}/>

                                <div style={{
                                    display: 'flex',
                                    marginTop: 15,
                                    marginBottom: marginBottomPojectName,
                                    flexDirection: 'column',
                                    height: 150,
                                    justifyContent: 'center'
                                }} className={'transitionLongue'}>

                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        opacity: opacityProjects,
                                        width: 300
                                    }} className={'transitionLongue'}>
                                        <p className={'projectName'}>{projects[indexProject]?.name.toUpperCase()}</p>
                                        <p className={'projectLocDate'}>{projects[indexProject]?.place} - {projects[indexProject]?.date}</p>
                                    </div>

                                    <div className={'transitionLongue'} style={{
                                        display: 'flex',
                                        width: 300,
                                        justifyContent: 'center',
                                        opacity: opacityProjects,
                                    }}>
                                        <div className={'arrow'} onClick={previousProject}>
                                            <img src={leftArrow} alt=""
                                                 style={{width: 5, transform: 'rotate(180deg)', marginRight: -5}}/>
                                            <div className={'bar'}/>
                                        </div>

                                        <div className={'arrow'} style={{justifyContent: 'flex-end'}}
                                             onClick={nextProject}>
                                            <div className={'bar'}/>
                                            <img src={leftArrow} alt="" style={{width: 5, marginLeft: -5}}/>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className={'rightPart'} style={{opacity: opacityProjects, marginTop: -40}}>
                            <div className={'transitionLongue'} style={{height: marginTopProject}}/>
                            <ProjectPictures opacity={1} templateNumber={projects[indexProject]?.template}
                                             pictures={projects[indexProject]?.pictures ?? []} maDiv={maDiv} isPhone={true}/>
                        </div>

                    </div>
                    <div ref={refFooter}>
                        <Footer setWidthFooter={setWidthFooter} widthFooter={widthFooter} isPhone={true} projectPage={true}/>
                    </div>
                </div>
                :
                <div className={'page'} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <div className={'projectPage'} style={{opacity: widthFooter === '0' ? 1 : 0.4}} onClick={() => {
                        if (widthFooter !== '0') {
                            setWidthFooter('0')
                        }
                    }}>
                        <div className={'leftPart'}>
                            <div className={'leftPartContent'}>

                                <img src={logoPR} alt="" className={'agencyLogo'}
                                     onClick={() => history.push('/')}/>

                                <div style={{
                                    display: 'flex',
                                    marginTop: 15,
                                    marginBottom: marginBottomPojectName,
                                    flexDirection: 'column',
                                    height: 250,
                                    justifyContent: 'center'
                                }} className={'transitionLongue'}>

                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        opacity: opacityProjects,
                                        width: 300
                                    }} className={'transitionLongue'}>
                                        <p className={'projectName'}>{projects[indexProject]?.name.toUpperCase()}</p>
                                        <p className={'projectLocDate'}>{projects[indexProject]?.place} - {projects[indexProject]?.date}</p>
                                    </div>

                                    <div className={'transitionLongue'} style={{
                                        display: 'flex',
                                        width: 300,
                                        justifyContent: 'center',
                                        opacity: opacityProjects,
                                    }}>
                                        <div className={'arrow'} onClick={previousProject}>
                                            <img src={leftArrow} alt=""
                                                 style={{width: 5, transform: 'rotate(180deg)', marginRight: -5}}/>
                                            <div className={'bar'}/>
                                        </div>

                                        <div className={'arrow'} style={{justifyContent: 'flex-end'}}
                                             onClick={nextProject}>
                                            <div className={'bar'}/>
                                            <img src={leftArrow} alt="" style={{width: 5, marginLeft: -5}}/>
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <div style={{
                                width: 270,
                                display: 'flex',
                                justifyContent: 'center',
                                position: 'absolute',
                                bottom: 0,
                                marginBottom: 5,
                                opacity: opacityContact
                            }} className={'transitionLongue'}>
                                <p className={'hover'} style={{marginTop: 0, fontSize: 13, fontWeight: '600'}}
                                   onClick={() => setWidthFooter('calc(100vw - 485px)')}>Contact</p>
                            </div>

                        </div>

                        <div className={'rightPart'} style={{opacity: opacityProjects}}>
                            <div className={'transitionLongue'} style={{height: marginTopProject}}/>
                            <ProjectPictures opacity={1} templateNumber={projects[indexProject]?.template}
                                             pictures={projects[indexProject]?.pictures ?? []} maDiv={maDiv}/>
                        </div>

                    </div>

                    <div ref={refFooter}>
                        <Footer setWidthFooter={setWidthFooter} widthFooter={widthFooter}/>
                    </div>

                </div>
            }
        </>
    )
}
