import React, {useEffect} from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import ProjectsPage from "./pages/ProjectsPage";
import HomePage from "./pages/HomePage";

function App() {


  return (
      <Router>
        <Switch>

          {/* Main pages */}

          <Route path="/" exact component={HomePage}/>
            <Route path="/fr" component={HomePage}/>
            <Route path="/en" component={HomePage}/>
          <Route path="/projects" exact component={ProjectsPage} />

        </Switch>
      </Router>
  );
};

export default App;
