import React, {useEffect, useState, useRef} from 'react';
import { animateScroll as scroll } from 'react-scroll';

export default function ProjectPictures({templateNumber, opacity, pictures, isNewProject, setPictures, maDiv, isPhone}) {

    const handleFile = (e) => {
        e.preventDefault();
        if (e && e.target && e.target.files) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                setPictures(previousState => {
                    const nextState = [...previousState];
                    nextState[e.target.id] = reader.result;
                    return nextState;
                });
            };
            reader.readAsDataURL(file);
        }
    }

    const [width, setWidth] = useState(0);
    const [minoration, setMinoration] = useState(0)

    window.addEventListener('resize', Update);
    useEffect(() => {
        Update();
    }, []);

    function Update() {
        setWidth(window.innerWidth);
        if (width < 900) {
            setMinoration(-100)
        } else {
            setMinoration(0)
        }
    }

    const containerRef = useRef(null);

    useEffect(() => {
        if (containerRef.current) {
            const container = containerRef.current;
            let position = 0;
            const step = 1; // Ajustez cette valeur pour rendre le défilement plus rapide ou plus lent
            const intervalId = setInterval(() => {
                position += step;
                scroll.scrollTo(position, { container });
                // Arrêter le défilement si on a atteint le bas
                if (position >= container.scrollHeight - container.clientHeight) {
                    clearInterval(intervalId);
                }
            }, 20); // Ajustez cette valeur pour rendre le défilement plus rapide ou plus lent

            return () => clearInterval(intervalId); // Nettoyage à la désinscription du composant
        }
    }, []);

    return (
        <div className={'transition'} style={{opacity: opacity}}>

            {templateNumber === 0 ?
                <div className={width < 600 ? 'phonePhotosOption1' : 'photosOption1'}>
                    <div className={'inner'} ref={maDiv}>
                        <label htmlFor={isNewProject ? "0" : null}>
                            <img src={pictures[0]} alt="" className={'firstImage'}/>
                        </label>
                        <div style={{display: 'flex', flexDirection: width > 1200 ? 'row' : 'column'}}>
                            {pictures[1] &&
                                <label htmlFor={isNewProject ? "1" : null}>
                                    <img src={pictures[1]} alt="" className={width > 1200 ? 'secondImage' : 'singleSecondImage'}/>
                                </label>
                            }
                            {pictures[2] &&
                                <label htmlFor={isNewProject ? "2" : null}>
                                    <img src={pictures[2]} alt="" className={width > 1200 ? 'thirdImage' : 'singleThirdImage'}/>
                                </label>
                            }

                        </div>

                        <div style={{display: 'flex', flexDirection: width > 1200 ? 'row' : 'column', marginBottom: 70}}>
                            {pictures[3] &&
                                <label htmlFor={isNewProject ? "4" : null}>
                                    <img src={pictures[3]} alt="" className={width > 1200 ? 'thirdImage' : 'singleThirdImage'}/>
                                </label>
                            }
                            {pictures[4] &&
                                <label htmlFor={isNewProject ? "3" : null}>
                                    <img src={pictures[4]} alt="" className={width > 1200 ? 'secondImage' : 'singleSecondImage'} style={{marginRight: 0, marginLeft: isPhone ? 0 : 3}}/>
                                </label>
                            }
                        </div>
                    </div>
                </div>
                    :
                <div className={width < 600 ? 'phonePhotosOption1' : 'photosOption1'}>
                    <div className={'inner'} ref={maDiv}>
                        <label htmlFor={isNewProject ? "0" : null}>
                            <img src={pictures[0]} alt="" className={'firstImage'}/>
                        </label>

                        <div style={{display: 'flex', flexDirection: width > 1200 ? 'row' : 'column', marginBottom: 70}}>
                            {pictures[1] &&
                                <label htmlFor={isNewProject ? "1" : null}>
                                    <img src={pictures[1]} alt="" className={width > 1200 ? 'secondImage' : 'singleSecondImage'} style={{height: width > 1200 ? 'calc((100vw - 550px) *0.45)' : null}}/>
                                </label>
                            }

                            {pictures[2] &&
                                <label htmlFor={isNewProject ? "1" : null}>
                                    <img src={pictures[2]} alt="" className={width > 1200 ? 'secondImage' : 'singleSecondImage'} style={{height: width > 1200 ? 'calc((100vw - 550px) *0.45)' : null}}/>
                                </label>
                            }

                            {pictures[3] &&
                                <label htmlFor={isNewProject ? "1" : null}>
                                    <img src={pictures[3]} alt="" className={width > 1200 ? 'secondImage' : 'singleSecondImage'} style={{height: width > 1200 ? 'calc((100vw - 550px) *0.45)' : null}}/>
                                </label>
                            }
                        </div>

                    </div>
                </div>
            }

            {isNewProject && <div>
                <input type="file" id="0" accept={'image/*'} style={{display: 'none'}} onChange={handleFile}/>
                <input type="file" id="1" accept={'image/*'} style={{display: 'none'}} onChange={handleFile}/>
                <input type="file" id="2" accept={'image/*'} style={{display: 'none'}} onChange={handleFile}/>
                <input type="file" id="3" accept={'image/*'} style={{display: 'none'}} onChange={handleFile}/>
                <input type="file" id="4" accept={'image/*'} style={{display: 'none'}} onChange={handleFile}/>
            </div>}
        </div>
    )


}
