// Import the functions you need from the SDKs you need
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC_nFzvi5XaEUZDb6AqOt4ANroa1Q28w4c",
    authDomain: "website-cc43b.firebaseapp.com",
    projectId: "website-cc43b",
    storageBucket: "website-cc43b.appspot.com",
    messagingSenderId: "737451097407",
    appId: "1:737451097407:web:85d47a022cf834d205678a",
    measurementId: "G-LZK18RTQFC"
};

// Initialize Firebase
export const appFirebase = firebase.initializeApp(firebaseConfig);

export const firestore = appFirebase.firestore();
export const storage = appFirebase.storage();
